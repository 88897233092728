import React, { useState } from "react"
import Layout from "../components/Layout/Layout"
import HomeLayout from "../components/HomeLayout/HomeLayout"
import { useStaticQuery, graphql } from "gatsby"
import Flippy from "react-flippy/dist/Flippy"
import { FrontSide, BackSide } from "react-flippy/dist/FlippyCard"
import Img from "gatsby-image"
import { useMediaQuery } from "react-responsive"

const Pay = () => {
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 720px)",
  })
  const [isFlipped1, setIsFlipped1] = useState(false)
  const [isFlipped2, setIsFlipped2] = useState(false)
  const [isFlipped3, setIsFlipped3] = useState(false)
  const [isFlipped4, setIsFlipped4] = useState(false)

  const data = useStaticQuery(graphql`
    query {
      Pay_cmp: allFile(
        sort: { order: ASC, fields: name }
        filter: { sourceInstanceName: { eq: "Pay_cmp" } }
      ) {
        nodes {
          id
          name
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      Pay_qr: allFile(
        sort: { order: ASC, fields: name }
        filter: { sourceInstanceName: { eq: "Pay_qr" } }
      ) {
        nodes {
          id
          name
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <HomeLayout>
        <div
          onClick={e => {
            if (!isFlipped1) {
              setIsFlipped2(false)
              setIsFlipped3(false)
              setIsFlipped4(false)

              setIsFlipped1(true)
            } else {
              setIsFlipped1(false)
            }
          }}
        >
          <Flippy
            flipDirection="horizontal"
            flipOnClick={false}
            isFlipped={isFlipped1}
          >
            <FrontSide>
              <Img
                fluid={data.Pay_cmp.nodes[0].childImageSharp.fluid}
                alt="Front image"
              />
            </FrontSide>
            <BackSide>
              <Img
                fluid={data.Pay_qr.nodes[0].childImageSharp.fluid}
                alt="Back image"
                fadeIn={false}
              />
            </BackSide>
          </Flippy>
        </div>
        <div
          onClick={() => {
            if (!isFlipped2) {
              setIsFlipped1(false)
              setIsFlipped3(false)
              setIsFlipped4(false)

              setIsFlipped2(true)
            } else {
              setIsFlipped2(false)
            }
          }}
        >
          <Flippy flipDirection="horizontal" isFlipped={isFlipped2}>
            <FrontSide>
              <Img
                fluid={data.Pay_cmp.nodes[1].childImageSharp.fluid}
                alt="Front image"
              />
            </FrontSide>
            <BackSide>
              <Img
                fluid={data.Pay_qr.nodes[1].childImageSharp.fluid}
                alt="Back image"
                fadeIn={false}
              />
            </BackSide>
          </Flippy>
        </div>
        <div
          onClick={() => {
            if (!isFlipped3) {
              setIsFlipped1(false)
              setIsFlipped2(false)
              setIsFlipped4(false)

              setIsFlipped3(true)
            } else {
              setIsFlipped3(false)
            }
          }}
        >
          <Flippy flipDirection="horizontal" isFlipped={isFlipped3}>
            <FrontSide>
              <Img
                fluid={data.Pay_cmp.nodes[2].childImageSharp.fluid}
                alt="Front image"
              />
            </FrontSide>
            <BackSide>
              <Img
                fluid={data.Pay_qr.nodes[2].childImageSharp.fluid}
                alt="Back image"
                fadeIn={false}
              />
            </BackSide>
          </Flippy>
        </div>
        <div
          onClick={() => {
            if (!isFlipped4) {
              setIsFlipped1(false)
              setIsFlipped2(false)
              setIsFlipped3(false)

              setIsFlipped4(true)
            } else {
              setIsFlipped4(false)
            }
          }}
        >
          <Flippy flipDirection="horizontal" isFlipped={isFlipped4}>
            <FrontSide>
              <Img
                fluid={data.Pay_cmp.nodes[3].childImageSharp.fluid}
                alt="Front image"
              />
            </FrontSide>
            <BackSide>
              <Img
                fluid={data.Pay_qr.nodes[3].childImageSharp.fluid}
                alt="Back image"
                fadeIn={false}
              />
            </BackSide>
          </Flippy>
        </div>
        <>
          <Flippy
            flipDirection="horizontal"
            isFlipped={isFlipped4}
            style={{ opacity: 0 }}
          >
            <FrontSide>
              <Img
                fluid={data.Pay_cmp.nodes[3].childImageSharp.fluid}
                alt="Front image"
              />
            </FrontSide>
          </Flippy>
          <Flippy
            flipDirection="horizontal"
            isFlipped={isFlipped4}
            style={{ opacity: 0 }}
          >
            <FrontSide>
              <Img
                fluid={data.Pay_cmp.nodes[3].childImageSharp.fluid}
                alt="Front image"
              />
            </FrontSide>
          </Flippy>
          <Flippy
            flipDirection="horizontal"
            isFlipped={isFlipped4}
            style={{ opacity: 0 }}
          >
            <FrontSide>
              <Img
                fluid={data.Pay_cmp.nodes[3].childImageSharp.fluid}
                alt="Front image"
              />
            </FrontSide>
          </Flippy>
          <Flippy
            flipDirection="horizontal"
            isFlipped={isFlipped4}
            style={{ opacity: 0 }}
          >
            <FrontSide>
              <Img
                fluid={data.Pay_cmp.nodes[3].childImageSharp.fluid}
                alt="Front image"
              />
            </FrontSide>
          </Flippy>
        </>
      </HomeLayout>
    </Layout>
  )
}

export default Pay
